import React, { useState, useEffect } from "react";
import get from "lodash/get";
import { Link } from "@quintype/components";
import { useSelector, useDispatch } from "react-redux";
import throttle from "lodash/throttle";

import { MenuItem } from "../../menu-item";
import MenuRight from "../../../atoms/icons/menu-right";

import { OPEN_SEARCHBAR, OPEN_HAMBURGER_MENU, setNewNotification } from "../../../store/actions";
import Logo from "../../../atoms/icons/logo";
import HamburgerMenu from "../../../atoms/hamburger-menu";
import HamburgerMenus from "../hamburger-menus";
import StickyNavbar from "../../../molecules/sticky-navbar";
import HeaderRight from "../header-right";

import "./mobile-header.m.css";
import Modal from "../../../atoms/modal";
import NotificationBox from "../../../molecules/notification-box";

const MobileHeader = () => {
  const dispatch = useDispatch();
  // const notificationsData = useSelector((state) => get(state, ["notificationList"], {}));
  const pageType = useSelector((state) => get(state, ["qt", "pageType"], "home-page"));

  const getState = useSelector((state) => state);

  const primaryMenus = get(getState, ["qt", "data", "navigationMenu", "primaryMenus"], []);
  const secondaryMenus = get(getState, ["qt", "data", "navigationMenu", "secondaryMenus"], []);
  const isHamburgerMenuOpen = get(getState, ["isHamburgerMenuOpen"], false);
  const [showModal, setShowModal] = useState(false);
  const notificationList = get(getState, "notificationList");

  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const toggleHandler = () => {
    dispatch({
      type: OPEN_HAMBURGER_MENU,
      isHamburgerMenuOpen: !isHamburgerMenuOpen
    });
    dispatch({
      type: OPEN_SEARCHBAR,
      isSearchBarOpen: false
    });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      let previousScrollY = window.scrollY;
      const primaryMenu = document.querySelector(".primary-menu");
      const secondaryMenu = document.querySelector(".secondary-menu");
      function checkScroll() {
        const stickyShareTop = document.querySelector(".sticky-div")?.getBoundingClientRect().top;
        const currentScrollY = window.scrollY;
        if (window.scrollY > 76) {
          primaryMenu.classList.add("primary-menu-sticky");
          secondaryMenu.style.marginTop = primaryMenu.clientHeight + "px";
          if (pageType === "story-page") {
            if (primaryMenu.clientHeight >= stickyShareTop) {
              if (currentScrollY > previousScrollY) {
                primaryMenu.classList.add("hide-primary-menu");
              } else {
                primaryMenu.classList.remove("hide-primary-menu");
              }
            }
          }
        } else {
          primaryMenu.classList.remove("primary-menu-sticky");
          secondaryMenu.style.marginTop = "0px";
        }

        previousScrollY = currentScrollY;
      }

      document.addEventListener("scroll", checkScroll);
      return () => {
        document.removeEventListener("scroll", checkScroll);
      };
    }
  }, [pageType]);

  useEffect(() => {
    if (showModal) {
      window.localStorage.setItem("latestNotificationCreatedAt", notificationList[0]?.createdAt);
      dispatch(setNewNotification(false));
    }
  }, [showModal]);

  const getPrimaryMenus = () => {
    return (
      <div styleName="primary-menus">
        {primaryMenus.slice(0, 10).map((item, index) => (
          <div key={index} styleName="primary-menu-item">

            <MenuItem
              changeTextColor={item?.data?.color.toLowerCase() !== "#ffffff"}
              changeTextWeight={item?.data?.color.toLowerCase() !== "#ffffff"}
              item={item}
              menuStyle="menu-link"
              toggleHandler={() =>
                dispatch({
                  type: OPEN_SEARCHBAR,
                  isSearchBarOpen: false
                })
              }
            />

          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div styleName="header" id="mob-header">
        <div styleName="primary">
          <div>
            <Link href="/" styleName="logo">
              <Logo height="56" width="64.63" />
            </Link>
          </div>

          <div styleName="primary-right-wrapper">
            <HeaderRight openNotificationModal={openModal} />
          </div>
        </div>
        {showModal && (
          <Modal onClose={closeModal} isOpen={showModal}>
            <NotificationBox closeBtnHandler={closeModal} />
          </Modal>
        )}{" "}
        <div styleName="secondary" className="primary-menu">
          {/* <div>
            <HamburgerMenu
              isMobileHamburger={false}
              onMenuToggle={() => toggleHandler()}
              isMegaMenuOpen={isHamburgerMenuOpen}
            />
          </div>{" "} */}
          <div>{getPrimaryMenus()}</div>
        </div>
        <div styleName="menu-wrapper secondary-menu-wrapper" className="secondary-menu">
          {secondaryMenus.slice(0, 10).map((item, index) => (
            <React.Fragment key={index}>
              <div styleName="secondary-menu-item">
                <MenuRight />
                <MenuItem
                  item={item}
                  key={index}
                  type="secondary"
                  menuStyle="menu-link"
                  changeTextColor={item?.data?.color.toLowerCase() !== "#ffffff"}
                  changeTextWeight={item?.data?.color.toLowerCase() !== "#ffffff"}
                />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
      <HamburgerMenus />
      <StickyNavbar onMenuToggle={() => toggleHandler()} />
    </>
  );
};

export default MobileHeader;
